.conversation-item {

  padding: 1rem !important;
  border-bottom: 1px solid #e8e8e8 !important;
  background-color: #F9F9F9 !important;
  /* transition: all ease-in-out 0.25s; */
  &:hover, &.conversation-item--active {
    background-color: #e25555 !important;
    border-bottom: 1px solid #e25555 !important;
    cursor: pointer !important;

    .conversation-item-text {
      color: white;
      margin-left: 0.75rem;
    }
  }
}
