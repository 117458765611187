.incoming_msg_img {
    display: block;
    width: 6%;
}
.received_msg {
    display: block;
    padding: 0;
    vertical-align: top;
    margin-bottom: 1rem;
    width: auto;
}
.received_withd_msg > div {
    background: #ebebeb none repeat scroll 0 0;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 0.5rem 0.75rem;
    width: 100%;
}
.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}
.received_withd_msg { 
    display: inline-block;
    /* float: right; */
    width: auto;
}

.sent_msg > div {
    background: rgba(226, 85, 85, 1) none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0; color:#fff;
    padding: 0.5rem 0.75rem;
    width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
    display: inline-block;
    float: right;
    width: auto;
}
.input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.picture_preview {
    width: 250px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.picture_preview {
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
    filter: blur(2px);
}

.media_icon:hover .picture_preview {
    filter: blur(0px);
}

.picture_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media {
    border-radius: 4px;
    cursor: pointer;
    margin: 4px;
    display: flex;
    width: 220px;
    height: auto;
    justify-content: center;
    align-items: center;
}

.placeholder {
    background: rgba(22, 46, 52, 0.58);
}

.medias {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-flow: wrap;
    background: none !important;
    padding: 0 !important;
    margin-bottom: 0.5rem !important;
}

.media_icon {
    margin: 4px;
    position: relative;
    border-radius: 4px;
    top: 0;
    overflow: hidden;
    height: 220px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-basis: 220px;

}
