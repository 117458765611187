.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.custom.ant-layout-header {
    background: white;
    height: 6rem !important;
}

.custom.ant-layout {
    background: white;
}

.chats-window {
    border: 1px solid #e1e1e1;
}

.ant-spin-dot-item {
    /* background-color: #e25555 !important; */
}

.ant-spin-nested-loading > div > .ant-spin {
    top: 10vh !important;
}

.tools-block-button {
    margin-bottom: 0.5rem;
}