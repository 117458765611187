body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.hidden {
  display: none;
}

#root {
    height: 100vh;
}

.conversations-window-wrapper {
  height: inherit;
  background-color: white;
  color: black;
}

.conversations-window-container {
  position: relative;
  max-width: 85vw;
  height: calc(100% - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 8px auto;
}
